export const headSection = {
  logo: 'here',
  topTitle: 'Dedicated to Excellence',
  topSubTitle: 'ZIMSEC and Cambridge'
}

export const cardsData = [
  {'title': 'Cambridge', 'price': 250,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-1u-17-08-24-12-24-35-exam-revision.jpg',
    'desc': 'We provide a Cambridge International education with personalized support to meet diverse learning needs', 'id': 2},
  {'title': 'ZIMSEC', 'price': 100,
  'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-1u-17-08-24-12-25-58-exam-revision.jpg',
  'desc': 'We deliver a ZIMSEC education with customized support to accommodate different learning abilities', 'id': 1},
  {'title': 'Private Tutor', 'price': 95,
    'remote_image': 'https://i.ibb.co/0Yp9J5R/tutor-e-learning.png',
    'desc': 'Online persional tutor to help you with your revision 1 on 1...', 'id': 3},
  ];

export const offerData = [
  {'title': 'Academics', 'price': 250,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-1u-17-08-24-12-15-01-exam-revision.jpg',
    'desc': 'We offer both Cambridge International and ZIMSEC education with personalized ' +
      'support tailored to diverse learning abilities', 'id': 2},
  {'title': 'Sports', 'price': 100,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-1u-17-08-24-12-15-49-exam-revision.jpg',
    'desc': 'We offer a diverse range of sports programs with tailored guidance to encourage ' +
      'all students, regardless of skill level, to reach their full potential', 'id': 1},
  {'title': 'Club and Society', 'price': 95,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-1u-17-08-24-12-14-42-exam-revision.jpg',
    'desc': 'We offer a variety of clubs and societies, providing personalized support to help ' +
      'every student explore their interests and develop new skills.', 'id': 3},
];

export const howWeHelp = {
  howText: 'We are passionate about helping clients on how they can utilise their information technology (IT)' +
    ' to optimally achieve their business goals. Let our consultants soothe your IT headache.'
}

export const blogsData = {
  'topTitle': 'Blogs and Articles',
  'headerTitle': 'Categories',
  'categories': [
      {'cat_id': 1, 'cat_name': 'GCSE', 'cat_icon': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-01-02-22-22-44-47-exam-revision.jpg'},
      {'cat_id': 2, 'cat_name': 'A LEVEL', 'cat_icon': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-01-02-22-22-44-47-exam-revision.jpg'},
      {'cat_id': 3, 'cat_name': 'GENERAL', 'cat_icon': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-01-02-22-22-44-47-exam-revision.jpg'}]
}