import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import beeLogo from '../assets/static/images/king-edward-zishume.jpg'
// import wallPaper from './../assets/static/images/OMtmkr.jpg'
// import wallPaper from './../assets/static/images/school-children-learning-South-Africa.jpg'
import wallPaper from './../assets/static/images/wall5.png'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneAllIcon from '@material-ui/icons/DoneAll';
// import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { usePromiseTracker } from "react-promise-tracker";
// import { trackPromise } from 'react-promise-tracker';
import { countries} from "./configs/ServicedCountries";
import { BRAND_NAME } from "../shared/consts"
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';

import { headSection, cardsData, offerData, howWeHelp } from './configs/HomeData';

import LinearProgress from "@material-ui/core/LinearProgress";
import Loader from 'react-loader-spinner';
import {useFetch} from "../shared/utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: 'rgba(255,255,255)',
    backgroundBlendMode: 'soft-light',
    backgroundSize: 'cover',
    padding: '145px 0px 120px',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat'
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // boxShadow: '0 1px 3px 0 rgba(37,32,31,.3)',
    boxShadow: '0 6px 2px 0 rgba(0,0,0,0.08)',
    border: '1px solid #ededed'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
    marginBottom: '16px',
    marginTop: '40px',
  },
  cardContent: {
    flexGrow: 1,
  },
   root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch',
    },
  },
  buttonStyle: {
    width: '30ch'
  },
  drop: {
    width: '30ch',
    margin: '8px',
    paddingTop: '15px',
  },
  footer: {
    backgroundColor: '#2f3030',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  preFooter: {
    backgroundColor: '#dfe0e0',
    padding: theme.spacing(4),
    color: theme.palette.background.paper,
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#000000"
  }
})(Typography);


export default function Home() {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [apiData, loading, error] = useFetch('home');
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("success")
  const [notificationMessage, setNotificationMessage] = React.useState(
    "Success! Check your inbox for your first question. If you can not find your confirmation email in your normal inbox, it is worth checking in your spam or junk mail section.")
  const [country, setCountry] = React.useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const vertical = 'top';
  const horizontal = 'center';

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCourseChange = (event) => {
    setCountry(event.target.value);
  };

  const handleButtonClick = (page) => {

    handleNavigate(page)

  };

  useEffect(() => {
    window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
  },[]);

  let history = useHistory();

  function handleNavigate(page) {
    history.push({
      pathname: '/pricing',
    });
  }

  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
      <div
        style={{
         width: "100%",
         height: "100",
         display: "flex",
         justifyContent: "center",
         alignItems: "center"
        }}
        >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    );
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (country === ''){
      setOpen(true)
      setSeverity('error')
      setNotificationMessage('Please select region')
      return;
    }
    let country_url = country.replace(/\s+/g, '-').toLowerCase();

    history.push({
      pathname: `/exam-revision/${country_url}`,
    });
  }

  const allCountries = apiData.map((val, i) => {
    return (
      <MenuItem key={i} value={val['code'].toLowerCase()}>
        {val['name']}
      </MenuItem>
    )
  });

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Zimsec and Cambridge learning - Tutorial and Exam Preparation" />
        <meta name="theme-color" content="#055b84" />
        <meta name="msapplication-navbutton-color" content="#055b84"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="#055b84"/>
        <title>{ BRAND_NAME } - ZIMSEC and Cambridge learning</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{vertical, horizontal}}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity}>
            {notificationMessage}
          </Alert>
        </Snackbar>
        {isLoaded ?
          <Container maxWidth="md">
            <div>
              <LinearProgress />
            </div>
          </Container>
          :
            null
        }
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <div
              style={{backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '150px',
                backgroundImage: `url(${beeLogo})`,
                backgroundSize: 'contain',
                marginBottom: '15px'
              }}
            >

            </div>
            <div style={{textAlign: 'center'}}>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color="textPrimary"
                style={{fontWeight: 'bold', color: 'black'}}
                gutterBottom>
                <span
                  // style={{backgroundColor: 'rgb(245, 195, 0)', padding: '0.0em 0.2em 0.0em 0.2em'}}
                >
                  {headSection.topTitle}</span>
              </Typography>
            </div>
            <div style={{textAlign: 'center'}}>
              <Typography variant="h6" align="center" style={{color: 'black', fontWeight: 300}}>
                {headSection.topSubTitle}
            </Typography>
            </div>

            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item elevation={0} >
                  <Button variant="contained" color="secondary" onClick={() => handleButtonClick('services')}>
                    More Info
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined"
                          style={{color: 'black', border: '1px solid #7e7e7e'}}
                          onClick={() => handleButtonClick('contact')}
                  >
                    Get in Touch
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <div style={{backgroundColor: "whitesmoke"}}>
          <Container className={classes.cardGrid} style={{paddingTop: '10px'}} maxWidth="md">
            <WhiteTextTypography
              component="h1"
              variant="h5"
              align="center"
              style={{fontWeight: 'bold', marginTop: '64px', marginBottom: '20px'}}
            >
              Life at King Edward Academy:
            </WhiteTextTypography>
            <Grid container spacing={4}>
              {offerData.map((card) => (
                <Grid item key={card.id} xs={12} sm={6} md={4}>
                  <Card className={classes.card} elevation={0}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.remote_image}
                      title="Image title"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="h2"
                        style={{fontWeight: 'bold', textAlign: 'center'}}>
                        {card.title}
                      </Typography>
                      <Divider variant="middle" style={{ marginBottom: '15px', marginTop: '15px'}} />
                      <Typography style={{ textAlign: 'center', color: '#686868'}}>
                        {card.desc}
                      </Typography>
                    </CardContent>
                    <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        style={{fontWeight: 800}}
                        onClick={() => handleButtonClick(card.title)}>
                        Learn More
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>

          </Container>
        </div>


        {/*Our Expertise*/}

        <Container className={classes.cardGrid} maxWidth="md">
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="textPrimary"
            style={{fontWeight: 'bold', marginBottom: '40px'}}
            gutterBottom>
            Programme Features
          </Typography>
          <Grid container spacing={4}>
            {cardsData.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                <Card className={classes.card} elevation={0}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.remote_image}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="h2"
                        style={{fontWeight: 'bold', textAlign: 'center'}}>
                      {card.title}
                    </Typography>
                    <Divider variant="middle" style={{ marginBottom: '15px', marginTop: '15px'}} />
                    <Typography style={{ textAlign: 'center', color: '#686868'}}>
                      {card.desc}
                    </Typography>
                  </CardContent>
                  <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      style={{fontWeight: 800}}
                      onClick={() => handleButtonClick(card.title)}>
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* End Our Expertise*/}

        <Container className={classes.cardGrid} style={{paddingTop: '10px', display: 'None'}} maxWidth="md">
          <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              style={{fontWeight: 'bold', marginBottom: '40px'}}
              gutterBottom>
              How We Can Help?
            </Typography>
          <Typography>
            {howWeHelp.howText}
          </Typography>
          <List style={{backgroundColor: 'whitesmoke', marginTop: '20px'}}>
            <Grid container spacing={3}>

              <Grid item xs={12} sm={6}>
                <ListItem>
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                  <div className='offer'>
                    <ListItemText
                      disableTypography
                      primary={<Typography className='offer' type="body2" style={{ fontWeight: 800, fontSize: '1.1rem', color: '#f5c300'}}>
                        Receive tailored advice for your exact needs</Typography>}
                    />
                  </div>
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItem>
                <ListItemIcon>
                  <DoneAllIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                    primary={<Typography className='offer' type="body2" style={{ fontWeight: 800,  fontSize: '1.1rem', color: 'black'}}>
                      Implementation options available</Typography>}
                />
                </ListItem>
              </Grid>

            </Grid>
          </List>
        </Container>
      </main>
      {/* End footer */}

    </React.Fragment>
  );
}