import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FacebookIcon from '@material-ui/icons/Facebook';
import greyLog from '../assets/static/images/e-lean-gray-scale.png';
import Grid from '@material-ui/core/Grid';
import { BRAND_NAME } from '../shared/consts';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#191919',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  root: {
      margin: theme.spacing(1),
      width: '25ch',
  },
  preFooter: {
    backgroundColor: '#f6f6f6;',
    padding: theme.spacing(4),
    color: '#1d1d1d',
    marginTop: '80px',
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://beeframeworks.co.uk/">
        Bee Frameworks
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer() {
  const classes = useStyles();

  const handleMenuClose = (social) => {
    console.log(social)
    social === 'facebook' ? window.open('https://www.facebook.com/IDaily-Academy-104568978021952', '_blank')
      : window.open('https://twitter.com', '_blank')
  };

  return (
    <div>
      <footer className={classes.preFooter}>
        <Typography variant="h6" align="center" gutterBottom>
          Connect with us
        </Typography>
        <div style={{textAlign: 'center', margin: '20px'}}>
          <FacebookIcon onClick={() => handleMenuClose('facebook')}
                        style={{ margin: '15px', width: '50px', height: '50px', cursor: 'pointer'}}/>
          {/*<TwitterIcon  onClick={() => handleMenuClose('twitter')}*/}
          {/*              style={{ margin: '15px', width: '50px', height: '50px', cursor: 'pointer'}}/>*/}
        </div>

      </footer>
      {/* Footer */}
      <footer className={classes.footer}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={4}>
            <div style={{color: 'white', textAlign: 'center'}}>
              <div
                style={{backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '100px',
                  backgroundImage: `url(${greyLog})`,
                  backgroundSize: 'contain',
                  marginBottom: '15px'
                }}
              >
              </div>
              <Typography variant='h6' style={{color: 'white', fontWeight: 700}}>
                { BRAND_NAME }
              </Typography>
              <Typography variant='h8' style={{color: 'white', fontSize: '0.8rem'}}>
                Zishumbe <br/>
                Masvingo <br/>
                Zimbabwe <br/>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{color: 'white', textAlign: 'center'}}>

              <Typography variant='h6' style={{color: '#999', fontWeight: 700, paddingBottom: '20px'}}>
                Services
              </Typography>

              <div style={{fontSize: '0.8rem'}}>
                <Link color="inherit" href="https://www.idailyacademy.com/pricing">
                  Cambridge
                </Link> <br/><br/>
                <Link color="inherit" href="https://www.idailyacademy.com/pricing">
                  ZIMSEC
                </Link> <br/><br/>
                <Link color="inherit" href="https://www.idailyacademy.com/pricing">
                  Sports
                </Link>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{color: 'white', textAlign: 'center'}}>
              <Typography variant='h6' style={{color: '#999', fontWeight: 700, paddingBottom: '20px'}}>
                Social Media
              </Typography>
              <div style={{fontSize: '0.8rem'}}>
                {/*<Link color="inherit" href="https://exukonline.com">*/}
                {/*  LinkedIn*/}
                {/*</Link> */}
                {/*<br/><br/>*/}
                <Link color="inherit" href="https://www.facebook.com/IDaily-Academy-104568978021952" target="_blank">
                  Facebook
                </Link> <br/><br/>
              </div>
            </div>
          </Grid>
        </Grid>
        <hr style={{marginBottom: '20px', border: '1px solid #3a3a3a', marginTop: '25px'}}/>
        <Copyright />
      </footer>
      {/* End footer */}
    </div>
  )
}

export default Footer;